<template>
    <ValidationObserver ref="validator" slim>
        <ValidationProvider v-slot="{ errors }" rules="required">
            <div class="authorization">
                <v-text-field
                    v-model="phone"
                    label="Телефон"
                    :error-messages="errors[0]"
                    type="tel"
                    autocomplete="off"
                    outlined
                    @keyup.enter="confirm"
                />
                <v-btn
                    color="primary"
                    @click="confirm">Авторизоваться</v-btn>
            </div>
        </ValidationProvider>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        name: 'Authorization',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            phone: '',
        }),
        methods: {
            async confirm() {
                const valid = await this.$refs['validator'].validate();
                if(valid) {
                    await store.dispatch('auth/login', { phone: this.phone });
                    await this.$router.push({ name: 'user-confirmation' });
                }
            },
            async registration() {
                await this.$router.push({ name: 'user-registration' });
            }
        }
    }
</script>

<style lang="scss">
.authorization {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 30%;
    left: 50%;
    margin: 0 0 0 -200px;
}
</style>
